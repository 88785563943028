<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.special')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="navbox">
            <div class="navtxt">1、{{$t('message.specialtxt1')}}</div>
            <!-- <div class="navtxt">2、{{$t('message.specialtxt2')}}</div> -->
            <div class="navtxt">2、{{$t('message.specialtxt3')}}</div>
        </b-container>
        <!-- pc布局【商品展示】 -->
        <b-container class="pc-navbox">
            <b-row>
                <b-col class="pc-goodinfoimg">
                    <div class="pc-good_maximg">
                        <div class="pc-good-item"><img :src="shopdataImg[imgIndex]" alt=""/></div>
                    </div>
                    <div class="pc-good_minimg">
                        <div class="pc-good-item" @click="getSwitchImg(index)" :class="imgIndex==index?'pc-cur':''" v-for="(item,index) in shopdataImg" :key="index">
                            <img :src="item" alt=""/>
                        </div>
                    </div>
                </b-col>
                <b-col class="pc-goodinfo">
                    <div class="pc-title">{{$t('message.special')}}</div>
                    <div class="pc-money">
                        {{$t('message.m')}}{{shopInfoObj.moneyOne}}
                        <div class="pc-moneybefor">
                            {{$t('message.m')}}
                            {{languageName=='cn'?shopInfoObj.cn_price:''}}
                            {{languageName=='en'?shopInfoObj.en_price:''}}
                            {{languageName=='it'?shopInfoObj.it_price:''}}    
                        </div>
                    </div>
                    <div class="pc-tit_tab">{{$t('message.specialshop')}}</div>
                    <div class="pc-tit_span">
                        <span class="pc-span" v-for="(item,index) in tablist" :key="index" :class="shopnameindex==index?'pc-cur':''" @click="getSwitchShop(item,index)">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                        </span>
                    </div>
                    <div class="pc-tit_tab">{{$t('message.color')}}</div>
                    <div class="pc-tit_span">
                        <span class="pc-span" v-for="(item,index) in tablistcolor" :key="index" :class="shopcolorindex==index?'pc-cur':''" @click="getSwitchShopColor(item,index)">
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}
                        </span>
                    </div>
                    <div class="pc-tit_tab">{{$t('message.num')}}</div>
                    <div class="pc-tit_tab_btn">
                        <span class="pc-leftbtn" @click="getRemoveItem()"><span class="el-icon-minus"></span></span>
                        <span class="pc-lefttxt">{{shopNumber}}</span>
                        <span class="pc-leftbtn" @click="getAddItem()"><span class="el-icon-plus"></span></span>
                    </div>
                    <div class="pc-btn">
                        <div class="pc-good_btn pc-caradd" @click="getAddShopCar(shopInfoObj)">{{$t('message.addcar')}}</div>
                        <div class="pc-good_btn pc-buypush" @click="getPayNow">{{$t('message.letpay')}}</div>
                    </div>
                    <div class="pc-paytype">{{$t('message.paytype')}}：
                        <span class="pc-payicon pc-zfb"></span>
                        <span class="pc-payicon pc-wx"></span>
                        <span class="pc-payicon pc-visa"></span>
                        <span class="pc-payicon pc-load"></span>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- wap布局【商品展示】 -->
        <b-container class="wap-navbox">
            <b-row>
                <div class="wap-goodinfoimg">
                    <div class="wap-good_maximg">
                        <div class="wap-good-item"><img :src="shopdataImg[imgIndex]" alt=""></div>
                    </div>
                    <div class="wap-good_minimg">
                        <div class="wap-good-item" @click="getSwitchImg(index)" :class="imgIndex==index?'wap-cur':''" v-for="(item,index) in shopdataImg" :key="index">
                            <img :src="item" alt=""/>
                        </div>
                    </div>
                </div>
                <div class="wap-goodinfo">
                    <div class="wap-title">{{$t('message.special')}}</div>
                    <div class="wap-money">
                        {{$t('message.m')}}{{shopInfoObj.moneyOne}}
                        <div class="wap-moneybefor">
                            {{$t('message.m')}}
                            {{languageName=='cn'?shopInfoObj.cn_price:''}}
                            {{languageName=='en'?shopInfoObj.en_price:''}}
                            {{languageName=='it'?shopInfoObj.it_price:''}}
                        </div>
                    </div>
                    <div class="wap-tit_tab">{{$t('message.specialshop')}}</div>
                    <div class="wap-tit_span">
                        <span class="wap-span" v-for="(item,index) in tablist" :key="index" :class="shopnameindex==index?'wap-cur':''" @click="getSwitchShop(item,index)">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                        </span>
                    </div>
                    <div class="wap-tit_tab">{{$t('message.color')}}</div>
                    <div class="wap-tit_span">
                        <span class="wap-span" v-for="(item,index) in tablistcolor" :key="index" :class="shopcolorindex==index?'wap-cur':''" @click="getSwitchShopColor(item,index)">
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}
                        </span>
                    </div>
                    <div class="wap-tit_tab">{{$t('message.num')}}</div>
                    <div class="wap-tit_tab_btn">
                        <span class="wap-leftbtn" @click="getRemoveItem()"><span class="el-icon-minus"></span></span>
                        <span class="wap-lefttxt">{{shopNumber}}</span>
                        <span class="wap-leftbtn" @click="getAddItem()"><span class="el-icon-plus"></span></span>
                    </div>
                    <div class="wap-btn">
                        <div class="wap-good_btn wap-caradd" @click="getAddShopCar(shopInfoObj)">{{$t('message.addcar')}}</div>
                        <div class="wap-good_btn wap-buypush" @click="getPayNow">{{$t('message.letpay')}}</div>
                    </div>
                    <div class="wap-paytype">{{$t('message.paytype')}}：
                        <span class="wap-payicon wap-zfb"></span>
                        <span class="wap-payicon wap-wx"></span>
                        <span class="wap-payicon wap-visa"></span>
                        <span class="wap-payicon wap-load"></span>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {config,yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'home',
    components:{
        
    },
    data(){
        return{
            token:'',
            login_user_info:{},
            shopNumber:1,
            languageName:'',
            languageObj:{},
            shopnameindex:0,
            tablist:[],
            shopcolorindex:0,
            goodspectid:0,
            tablistcolor:[],
            imgIndex:0,
            shopInfoObj:{},
            shopdataImg:[],
            shopdata:[],
            userPople:''//userPople:'company' 企业用户，数量10+
        }
    },
    watch:{
        
    },
    computed:{
       
    },
    methods: {
        // 优惠商品列表
        getShopList(){
            let params = {
                typeid:'',//（一级类型id）、
                typetwoid:'',//（二级类型id）、
                ishome:0,//（是否首页指定）、
                page:1,//（页数）、
                size:10,//（每页条数）、
                iscompany:this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
            }
            this.$http.api_goods_shopdiscounts(params).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.tablist = res.data;
                            this.getShopInfo(res.data[0]);
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 切换商品图片
        getSwitchImg(index){
            this.imgIndex = index;
        },
        // 切换商品数据
        getSwitchShop(item,index){
            this.shopnameindex = index;
            this.shopNumber = 1;
            this.getShopInfo(item);
        },
        // 切换商品颜色
        getSwitchShopColor(item,index){
            if(this.userPople=='company'){
                this.shopNumber = 10;
            }else{
                this.shopNumber = 1;
            }
            this.shopcolorindex = index;
            this.goodspectid = item.id;
        },
        getShopInfo(item){
            if(item){
                if(item.id){
                    // 商品详情接口
                    this.$http.api_goods_shopinfobyid({
                        id:item.id,
                        iscompany:this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
                    }).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    let goodsImgs = res.data.goods_imgs.split(',');
                                    this.shopdataImg = goodsImgs;
                                    this.shopInfoObj = res.data;
                                    if(this.languageName=='cn'){
                                        if(res.data.cn_price){
                                            if(res.data.discounts!=0){
                                                res.data.money = res.data.cn_price*((res.data.discounts)/100);//100-
                                                res.data.moneyOne = res.data.money.toFixed(2);
                                            }else{
                                                res.data.moneyOne = res.data.cn_price;
                                            }
                                        }else{
                                            res.data.cn_price = 0;
                                            res.data.moneyOne = res.data.cn_price;
                                        }
                                    }else if(this.languageName=='en'){
                                        if(res.data.en_price){
                                            if(res.data.discounts!=0){
                                                res.data.money = res.data.en_price*((res.data.discounts)/100);//100-
                                                res.data.moneyOne = res.data.money.toFixed(2);
                                            }else{
                                                res.data.moneyOne = res.data.en_price;
                                            }
                                        }else{
                                            res.data.en_price = 0;
                                            res.data.moneyOne = res.data.en_price;
                                        }
                                    }else if(this.languageName=='it'){
                                        if(res.data.it_price){
                                            if(res.data.discounts!=0){
                                                res.data.money = res.data.it_price*((res.data.discounts)/100);//100-
                                                res.data.moneyOne = res.data.money.toFixed(2);
                                            }else{
                                                res.data.moneyOne = res.data.it_price;
                                            }
                                        }else{
                                            res.data.it_price = 0;
                                            res.data.moneyOne = res.data.it_price;
                                        }
                                    }
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                    // 商品规格
                    this.$http.api_goods_shopgsinfobyid({
                        id:item.id
                    }).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    this.tablistcolor = res.data;
                                    this.goodspectid = res.data[0].id;
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }
        },
        // 加入购物车
        getAddShopCar(item){
            if(this.token&&item){
                if(this.shopNumber>0){
                    let params = {
                        token:this.token,//（用户token）、
                        goods_id:item.id,//（商品id）、
                        goods_no:this.shopNumber,//（商品数量）、
                        is_delete:0,//（是否删除 1是 0否）、
                        is_add:1,//（是否添加 1是 0否）、
                        is_edit:0,//（是否 编辑 1是 0否）
                        goods_spect_id:this.goodspectid,//(商品规格id)
                    }
                    this.$http.api_order_editcart(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    ElementUI.Message({
                                        message:this.languageObj.carsuccesstip,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                }
                            }else if(res.code==-1){
                                this.$store.commit('setLoginNameStatus', {});
                                delLocalItem('login_user_info');
                                this.$root.Bus.$emit('userInfoClick', {});
                                this.$store.commit('setLoginStatus',true);
                                this.$router.push({path:'/'});
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }else{
                    ElementUI.Message({
                        message:this.languageObj.numbertip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
        // 单个商品数量加1
        getAddItem(){
            if(this.userPople=='company'){
                this.shopNumber = this.shopNumber+10
            }else{
                this.shopNumber++;
            }
        },
        // 单个商品数量减1
        getRemoveItem(){
            if(this.userPople=='company'){
                if(this.shopNumber>10){
                    this.shopNumber = this.shopNumber-10
                }else{
                    ElementUI.Message({
                        message:this.languageObj.numbertip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
            }else{
                if(this.shopNumber>1){
                    this.shopNumber--
                }else{
                    ElementUI.Message({
                        message:this.languageObj.numbertip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
            }
            // if(this.shopNumber>1){
            //     if(this.userPople=='company'){
            //         this.shopNumber = this.shopNumber-10
            //     }else{
            //         this.shopNumber--
            //     }
            // }else{
            //     ElementUI.Message({
            //         message:this.languageObj.numbertip,
            //         type:'error',
            //         center: true,
            //         offset:10,
            //     });
            //     return false;
            // }
        },
        // 立即购买
        getPayNow(){
            if(this.token){
                // 企业用户
                if(this.login_user_info.is_company==1){
                    if(!this.login_user_info.cinfo.company_name){
                        this.$router.push({path:'/uinfo',query:{type:3}});
                    }else{
                        this.$http.api_order_createOrderByGoodsid({
                            token:this.token,//（用户token）、
                            goodsid:this.shopInfoObj.id,//（商品id）、
                            goodsnum:this.shopNumber,//（商品数量）、
                            spectid:this.goodspectid,//（规格id）、
                            country:this.languageName,//（国家编码）
                        }).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.$router.push({path:'/shopfrom',query:{
                                            orderno:res.data.orderno,
                                            goodsid:this.shopInfoObj.id,
                                            goodsnum:this.shopNumber,
                                            spectid:this.goodspectid
                                        }});
                                    }
                                }else if(res.code==-1){
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }else{
                    // 普通用户
                    if(!this.login_user_info.family_name){
                        this.$router.push({path:'/uinfo',query:{type:1}});
                    }else{
                        this.$http.api_order_createOrderByGoodsid({
                            token:this.token,//（用户token）、
                            goodsid:this.shopInfoObj.id,//（商品id）、
                            goodsnum:this.shopNumber,//（商品数量）、
                            spectid:this.goodspectid,//（规格id）、
                            country:this.languageName,//（国家编码）
                        }).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.$router.push({path:'/shopfrom',query:{
                                            orderno:res.data.orderno,
                                            goodsid:this.shopInfoObj.id,
                                            goodsnum:this.shopNumber,
                                            spectid:this.goodspectid
                                        }});
                                    }
                                }else if(res.code==-1){
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }
                // if(!this.login_user_info.family_name&&!this.login_user_info.nickname){
                //     this.$router.push({path:'/uinfo',query:{type:1}});
                // }else if(!this.login_user_info.family_name&&this.login_user_info.nickname){
                //     this.$router.push({path:'/uinfo',query:{type:2}});
                // }else{
                //     this.$http.api_order_createOrderByGoodsid({
                //         token:this.token,//（用户token）、
                //         goodsid:this.shopInfoObj.id,//（商品id）、
                //         goodsnum:this.shopNumber,//（商品数量）、
                //         spectid:this.goodspectid,//（规格id）、
                //         country:this.languageName,//（国家编码）
                //     }).then((res)=>{
                //         if(res){
                //             if(res.code==1){
                //                 if(res.data){
                //                     this.$router.push({path:'/shopfrom',query:{
                //                         orderno:res.data.orderno,
                //                         goodsid:this.shopInfoObj.id,
                //                         goodsnum:this.shopNumber,
                //                         spectid:this.goodspectid
                //                     }});
                //                 }
                //             }else if(res.code==-1){
                //                 this.$store.commit('setLoginNameStatus', {});
                //                 delLocalItem('login_user_info');
                //                 this.$root.Bus.$emit('userInfoClick', {});
                //                 this.$store.commit('setLoginStatus',true);
                //                 this.$router.push({path:'/'});
                //             }else{
                //                 ElementUI.Message({
                //                     message:res.message,
                //                     type:'error',
                //                     center: true,
                //                     offset:10,
                //                 });
                //                 return false;
                //             }
                //         }
                //     });
                // }
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "carsuccesstip":"加入购物车成功",
                "numbertip":"数量低于范围",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "carsuccesstip":"successfully Added to Cart",
                "numbertip":"Quantity is below the range",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "carsuccesstip":'Aggiunto al carrello',
                "numbertip":"Non e' abbastanza!",
            }
        }
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            this.login_user_info = login_user_info;
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople='company';
                this.shopNumber=10
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
        }
        this.getShopList(); // 优惠商品列表
    }
}
</script>
<style lang="scss" scoped>

.navtab{
    padding:20px 10px 10px;
    
}
.navbox{
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius:10px;
    background: #f4f7fe;
    padding:40px;
    .navtxt{
        padding:14px;
        width:50%;
        margin:0 auto;
        color: #4e89c1;
    }
    .pc-goodinfoimg{
        width:40%;
        .pc-good_maximg{
            height:400px;
            background: #fff;
            border-radius:10px;
            .pc-good-item{
                width:75%;
                height:100%;
                cursor: pointer;
                margin:0 auto;
                img{
                    cursor: pointer;
                    width: 100%;
                    height:100%;
                }
            }
        }
        .pc-good_minimg{
            height:100px;
            margin-top:20px;
            border-radius:10px;
            display: flex;
            -webkit-box-orient: horizontal;
            .pc-good-item{
                width:22%;
                height:100%;
                margin:0 2%;
                cursor: pointer;
                img{
                    cursor: pointer;
                    width: 100%;
                    height:100%;
                }
            }
            .pc-cur{
                border: 1px solid #1f73b7;
            }
        }
    }
    .pc-goodinfo{
        width: 60%;
        .pc-title{
            font-size:30px;
            font-weight: bold;
        }
        .pc-money{
            font-size:30px;
            color: #bf0000;
            border-bottom:1px solid #dde0ed;
            padding:2px 0 15px;
            margin-bottom: 15px;
            .pc-moneybefor{
                display: inline-block;
                
                margin-left: 10px;
                color: #95959e;
                text-decoration:line-through;
            }
        }
        .pc-tit_tab{
            padding:0px 0 5px;
            font-size:20px;
            font-weight: bold;
        }
        .pc-tit_span{
            margin:0 -10px 20px;
            .pc-span{
                display: inline-block;
                padding:4px 14px;
                border-radius: 5px;
                border:1px solid #95959e;
                margin:10px;
                
                color: #95959e;
                background: #ffffff;
            }
            .pc-cur{
                color: #1f73b7;
                border:1px solid #1f73b7;
            }
        }
        .pc-tit_tab_btn{
            display: inline-block;
            background: #fff;
            border:1px solid #95959e;
            border-radius: 6px;
            height:35px;
            line-height:35px;
            margin: 10px 0;
            .pc-leftbtn{
                display: inline-block;
                height:35px;
                padding:0 10px;
                color: #a5a5ad;
            }
            .pc-lefttxt{
                display: inline-block;
                height:34px;
                padding:0 16px;
                border-left:1px solid #95959e;
                border-right:1px solid #95959e;
            }
        }
        .pc-btn{
            display: flex;
            -webkit-box-orient: horizontal;
            width: 100%;
            padding:10px 0;
            .pc-good_btn{
                width:48%;
                text-align: center;
                cursor: pointer;
                border: 1px solid #2e2e2e;
                border-radius: 6px;
                padding:10px;
                
            }
            .pc-caradd{
                margin:0 2% 0 0;
                color: #6f6f74;
                background: #fff;
            }
            .pc-buypush{
                background: #2e2e2e;
                color: #fff;
            }
        }
        .pc-paytype{
            padding:5px 0;
            
            vertical-align: middle;
            .pc-payicon{
                display: inline-block;
                width: 38px;
                height:24px;
                margin-right: 10px;
                vertical-align: middle;
            }
            .pc-zfb{
                background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-wx{
                background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-visa{
                background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-load{
                background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                background-size: 100% 100%;
            }
        }
    }
}
.pc-navbox{
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius:10px;
    background: #f4f7fe;
    padding:40px;
    .pc-navtxt{
        padding:14px;
        width:50%;
        margin:0 auto;
        color: #4e89c1;
    }
    .pc-goodinfoimg{
        width:40%;
        .pc-good_maximg{
            height:400px;
            background: #fff;
            border-radius:10px;
            .pc-good-item{
                width:75%;
                height:100%;
                cursor: pointer;
                margin:0 auto;
                img{
                    cursor: pointer;
                    width: 100%;
                    height:100%;
                }
            }
        }
        .pc-good_minimg{
            height:100px;
            margin-top:20px;
            border-radius:10px;
            display: flex;
            -webkit-box-orient: horizontal;
            .pc-good-item{
                width:22%;
                height:100%;
                cursor: pointer;
                margin:0 2%;
                img{
                    cursor: pointer;
                    width: 100%;
                    height:100%;
                }
            }
            .pc-cur{
                border: 1px solid #1f73b7;
            }
        }
    }
    .pc-goodinfo{
        width: 60%;
        .pc-title{
            font-size:30px;
            font-weight: bold;
        }
        .pc-money{
            font-size:30px;
            color: #bf0000;
            border-bottom:1px solid #dde0ed;
            padding:2px 0 15px;
            margin-bottom: 15px;
            .pc-moneybefor{
                display: inline-block;
                
                margin-left: 10px;
                color: #95959e;
                text-decoration:line-through;
            }
        }
        .pc-tit_tab{
            padding:0px 0 5px;
            font-size:20px;
            font-weight: bold;
        }
        .pc-tit_span{
            margin:0 -10px 20px;
            .pc-span{
                display: inline-block;
                cursor: pointer;
                padding:4px 14px;
                border-radius: 5px;
                border:1px solid #95959e;
                margin:10px;
                
                color: #95959e;
                background: #ffffff;
            }
            .pc-cur{
                color: #1f73b7;
                border:1px solid #1f73b7;
            }
        }
        .pc-tit_tab_btn{
            display: inline-block;
            background: #fff;
            border:1px solid #95959e;
            border-radius: 6px;
            height:35px;
            line-height:35px;
            margin: 10px 0;
            .pc-leftbtn{
                display: inline-block;
                cursor: pointer;
                height:35px;
                padding:0 10px;
                color: #a5a5ad;
            }
            .pc-lefttxt{
                display: inline-block;
                height:34px;
                padding:0 16px;
                border-left:1px solid #95959e;
                border-right:1px solid #95959e;
            }
        }
        .pc-btn{
            display: flex;
            -webkit-box-orient: horizontal;
            width: 100%;
            padding:10px 0;
            .pc-good_btn{
                width:48%;
                text-align: center;
                cursor: pointer;
                border: 1px solid #2e2e2e;
                border-radius: 6px;
                padding:10px;
                
            }
            .pc-caradd{
                margin:0 2% 0 0;
                color: #6f6f74;
                background: #fff;
            }
            .pc-buypush{
                background: #2e2e2e;
                color: #fff;
            }
        }
        .pc-paytype{
            padding:5px 0;
            
            vertical-align: middle;
            .pc-payicon{
                display: inline-block;
                width: 38px;
                height:24px;
                margin-right: 10px;
                vertical-align: middle;
            }
            .pc-zfb{
                background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-wx{
                background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-visa{
                background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-load{
                background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                background-size: 100% 100%;
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .wrap{
        .wap-navbox{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
    //    .navbox{
    //         margin-top: 20px;
    //         border-radius:10px;
    //         background: #f4f7fe;
    //         padding:40px;
    //         .navtxt{
    //             padding:16px;
    //             width:50%;
    //             margin:0 auto;
    //             
    //         }
    //     }
        .wap-navbox{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
    //    .navbox{
    //         margin-top: 20px;
    //         border-radius:10px;
    //         background: #f4f7fe;
    //         padding:40px;
    //         .navtxt{
    //             padding:16px;
    //             width:50%;
    //             margin:0 auto;
    //             
    //         }
    //     }
        .wap-navbox{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .navbox{
            padding:40px;
            .navtxt{
                padding:16px;
                width:90%;
                margin:0 auto;
                
            }
        }
        .pc-navbox{display: none;}
        .wap-navbox{
            background: #fff;
            padding:10px 20px;
            width: 100%;
            .wap-navtxt{
                padding:14px;
                width:50%;
                margin:0 auto;
                color: #4e89c1;
            }
            .wap-goodinfoimg{
                width:100%;
                .wap-good_maximg{
                    height:300px;
                    background: #fff;
                    .wap-good-item{
                        width:75%;
                        height:100%;
                        margin:0 auto;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                }
                .wap-good_minimg{
                    height:80px;
                    margin-top:20px;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-good-item{
                        width:22%;
                        height:100%;
                        margin:0 2%;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                    .wap-cur{
                        border: 1px solid #1f73b7;
                    }
                }
            }
            .wap-goodinfo{
                width: 100%;
                padding:20px 0;
                .wap-title{
                    
                    font-weight: bold;
                }
                .wap-money{
                    
                    color: #bf0000;
                    border-bottom:1px solid #dde0ed;
                    padding:2px 0 15px;
                    margin-bottom: 15px;
                    .wap-moneybefor{
                        display: inline-block;
                        
                        margin-left: 10px;
                        color: #95959e;
                        text-decoration:line-through
                    }
                }
                .wap-tit_tab{
                    padding:0px 0 5px;
                    
                    font-weight: bold;
                }
                .wap-tit_span{
                    margin:0 0 10px;
                    .wap-span{
                        display: inline-block;
                        cursor: pointer;
                        padding:4px 14px;
                        border-radius: 5px;
                        border:1px solid #95959e;
                        margin:5px 5px;
                        
                        color: #95959e;
                        background: #ffffff;
                    }
                    .wap-cur{
                        color: #1f73b7;
                        border:1px solid #1f73b7;
                    }
                }
                .wap-tit_tab_btn{
                    display: inline-block;
                    background: #fff;
                    border:1px solid #95959e;
                    border-radius: 6px;
                    height:35px;
                    line-height:35px;
                    margin: 10px 0;
                    .wap-leftbtn{
                        display: inline-block;
                        cursor: pointer;
                        height:35px;
                        padding:0 10px;
                        color: #a5a5ad;
                    }
                    .wap-lefttxt{
                        display: inline-block;
                        height:34px;
                        padding:0 16px;
                        border-left:1px solid #95959e;
                        border-right:1px solid #95959e;
                    }
                }
                .wap-btn{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    width: 100%;
                    padding:10px 0;
                    .wap-good_btn{
                        width:48%;
                        border: 1px solid #2e2e2e;
                        border-radius: 6px;
                        padding:8px;
                        
                        text-align: center;
                    }
                    .wap-caradd{
                        margin:0 2% 0 0;
                        color: #6f6f74;
                        background: #fff;
                    }
                    .wap-buypush{
                        background: #2e2e2e;
                        color: #fff;
                    }
                }
                .wap-paytype{
                    padding:5px 0;
                    
                    vertical-align: middle;
                    .wap-payicon{
                        display: inline-block;
                        width: 38px;
                        height:24px;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    .wap-zfb{
                        background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-wx{
                        background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-visa{
                        background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-load{
                        background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
     .wrap{
        .navbox{
            padding:10px;
            margin: 10px 0;
            .navtxt{
                padding:6px;
                width:100%;
                
            }
        }
        .pc-navbox{display: none;}
        .wap-navbox{
            background: #fff;
            padding:10px 20px;
            width: 100%;
            .wap-navtxt{
                padding:14px;
                width:50%;
                margin:0 auto;
                color: #4e89c1;
            }
            .wap-goodinfoimg{
                width:100%;
                .wap-good_maximg{
                    height:300px;
                    background: #fff;
                    .wap-good-item{
                        width:75%;
                        height:100%;
                        margin:0 auto;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                }
                .wap-good_minimg{
                    height:80px;
                    margin-top:20px;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-good-item{
                        width:22%;
                        height:100%;
                        margin:0 2%;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                    .wap-cur{
                        border: 1px solid #1f73b7;
                    }
                }
            }
            .wap-goodinfo{
                width: 100%;
                padding:20px 0;
                .wap-title{
                    
                    font-weight: bold;
                }
                .wap-money{
                    
                    color: #bf0000;
                    border-bottom:1px solid #dde0ed;
                    padding:2px 0 15px;
                    margin-bottom: 15px;
                    .wap-moneybefor{
                        display: inline-block;
                        
                        margin-left: 10px;
                        color: #95959e;
                        text-decoration:line-through
                    }
                }
                .wap-tit_tab{
                    padding:0px 0 5px;
                    
                    font-weight: bold;
                }
                .wap-tit_span{
                    margin:0 0 10px;
                    .wap-span{
                        display: inline-block;
                        cursor: pointer;
                        padding:4px 14px;
                        border-radius: 5px;
                        border:1px solid #95959e;
                        margin:5px 5px;
                        
                        color: #95959e;
                        background: #ffffff;
                    }
                    .wap-cur{
                        color: #1f73b7;
                        border:1px solid #1f73b7;
                    }
                }
                .wap-tit_tab_btn{
                    display: inline-block;
                    background: #fff;
                    border:1px solid #95959e;
                    border-radius: 6px;
                    height:35px;
                    line-height:35px;
                    margin: 10px 0;
                    .wap-leftbtn{
                        display: inline-block;
                        cursor: pointer;
                        height:35px;
                        padding:0 10px;
                        color: #a5a5ad;
                    }
                    .wap-lefttxt{
                        display: inline-block;
                        height:34px;
                        padding:0 16px;
                        border-left:1px solid #95959e;
                        border-right:1px solid #95959e;
                    }
                }
                .wap-btn{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    width: 100%;
                    padding:10px 0;
                    .wap-good_btn{
                        width:48%;
                        border: 1px solid #2e2e2e;
                        border-radius: 6px;
                        padding:8px;
                        
                        text-align: center;
                    }
                    .wap-caradd{
                        margin:0 2% 0 0;
                        color: #6f6f74;
                        background: #fff;
                    }
                    .wap-buypush{
                        background: #2e2e2e;
                        color: #fff;
                    }
                }
                .wap-paytype{
                    padding:5px 0;
                    
                    vertical-align: middle;
                    .wap-payicon{
                        display: inline-block;
                        width: 38px;
                        height:24px;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    .wap-zfb{
                        background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-wx{
                        background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-visa{
                        background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-load{
                        background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
    
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}
</style>